<template>
  <div>
    <ItemListTable />
  </div>
</template>

<script>
import ItemListTable from "@/modules/item/components/ItemListTable.vue";

export default {
  name: "ProductsList",
  data() {
    return {};
  },
  components: {
    ItemListTable
  }
};
</script>
